@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");
body {
  margin: 0;
  font-family: "Roboto Mono", monospace;
  background-color: #2b2b2b;
  color: whitesmoke;
}
h1,
h2,
h3,
h4,
h5 {
  padding: 0;
  margin: 0;
}
