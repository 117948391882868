@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap);
body {
  margin: 0;
  font-family: "Roboto Mono", monospace;
  background-color: #2b2b2b;
  color: whitesmoke;
}
h1,
h2,
h3,
h4,
h5 {
  padding: 0;
  margin: 0;
}

/* Animated Circles CSS */

.circles {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 90%;
  margin: 0 auto;
  padding: 0;
  height: 0;
  z-index: 3;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(238, 243, 243, 0.5);
  -webkit-animation: animate 25s linear infinite;
          animation: animate 25s linear infinite;
  bottom: -20vh;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-duration: 20s;
          animation-duration: 20s;
  border-radius: 50%;

  background: rgba(238, 243, 243, 0.5);
}

.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-duration: 25s;
          animation-duration: 25s;
  border-radius: 50%;

  background: rgba(245, 248, 248, 0.575);
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-duration: 20s;
          animation-duration: 20s;
  border-radius: 50%;

  background: rgba(238, 243, 243, 0.5);
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-duration: 15s;
          animation-duration: 15s;
  border-radius: 50%;

  background: rgba(238, 243, 243, 0.5);
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-duration: 20s;
          animation-duration: 20s;
  border-radius: 50%;

  background: rgba(238, 243, 243, 0.5);
}

.circles li:nth-child(6) {
  left: 75%;
  width: 90px;
  height: 90px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-duration: 25s;
          animation-duration: 25s;
  border-radius: 50%;

  background: rgba(238, 243, 243, 0.5);
}

.circles li:nth-child(7) {
  left: 35%;
  width: 50px;
  height: 50px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-duration: 30s;
          animation-duration: 30s;
  border-radius: 50%;

  background: rgba(238, 243, 243, 0.5);
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-duration: 35s;
          animation-duration: 35s;
  border-radius: 50%;

  background: rgba(238, 243, 243, 0.5);
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-duration: 40s;
          animation-duration: 40s;
  border-radius: 50%;

  background: rgba(238, 243, 243, 0.5);
}

.circles li:nth-child(10) {
  left: 85%;
  width: 50px;
  height: 50px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-duration: 45s;
          animation-duration: 45s;
  border-radius: 50%;

  background: rgba(238, 243, 243, 0.5);
}

@-webkit-keyframes animate {
  0% {
    -webkit-transform: translateY(0) rotate(0deg);
            transform: translateY(0) rotate(0deg);
    opacity: 0;
  }
  50% {
    -webkit-transform: translateY(-60vh) rotate(360deg);
            transform: translateY(-60vh) rotate(360deg);
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateY(-120vh) rotate(720deg);
            transform: translateY(-120vh) rotate(720deg);
    opacity: 0;
  }
}

@keyframes animate {
  0% {
    -webkit-transform: translateY(0) rotate(0deg);
            transform: translateY(0) rotate(0deg);
    opacity: 0;
  }
  50% {
    -webkit-transform: translateY(-60vh) rotate(360deg);
            transform: translateY(-60vh) rotate(360deg);
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateY(-120vh) rotate(720deg);
            transform: translateY(-120vh) rotate(720deg);
    opacity: 0;
  }
}

.socials {
  margin: 0;
  width: 100%;
  padding: 50px 0;
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.socials a {
  color: white;
  font-weight: 900;
  text-decoration: none;
}
.socials a:hover {
  opacity: 0.7;
}/*# sourceMappingURL=contact.css.map */
.intro {
  position: relative;
  align-items: center;
  display: flex;
  z-index: 10;
  justify-content: space-evenly;
}
.intro .about {
  margin-left: 5%;
  margin-top: 5%;
}
.intro .about h1 {
  font-size: 4.2rem;
  font-weight: bold;
  line-height: 1;
}
.intro .about p {
  font-size: 0.95rem;
  line-height: 1.8rem;
  font-weight: 500;
}
.intro .about .elevator-pitch {
  width: 70%;
}

@media screen and (max-width: 700px) {
  .intro .about h1 {
    font-size: 40px;
  }
  .intro .about p {
    font-size: 16px;
  }
  .intro .about .elevator-pitch {
    width: 90%;
  }
}/*# sourceMappingURL=introduction.css.map */
.projects {
  padding: 3%;
  position: relative;
  z-index: 10;
  min-height: 100vh;
}
.projects h1 {
  font-size: 20px;
  text-align: center;
}
.projects .project-list .project {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  background-color: rgb(0, 0, 0);
  cursor: pointer;
  color: white;
  border-radius: 4px;
  margin: 50px auto;
  padding: 5%;
  min-height: 250px;
  width: 80%;
  transition: 0.4s;
}
.projects .project-list .project img {
  width: 100%;
}
.projects .project-list .project a {
  font-size: 0.9rem;
  color: #fff;
  text-decoration: none;
}
.projects .project-list .project .technologies {
  display: flex;
  cursor: pointer;
  margin-bottom: 10px;
  min-width: 50%;
}
.projects .project-list .project .technologies p {
  margin-right: 2%;
  border-radius: 2.5px;
  background-color: black;
  padding: 5px;
  font-size: 0.6rem;
}
.projects .project-list .project:focus {
  outline: none !important;
}
.projects .project-list .project:hover {
  background-color: black;
  width: 81%;
}

@media screen and (min-width: 1000px) {
  .projects h1 {
    font-size: 50px;
  }
  .project-list {
    display: grid;
    align-items: center;
    grid-template-columns: 50% 50%;
  }
}/*# sourceMappingURL=projects.css.map */
